.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 100%;
}

.loading-text {
  font-family: 'Inter', sans-serif;
  line-height: 20px;
  font-size: 18px;
  width: 318px;
  text-align: center;
}

/* TEMP: temp solution for centering content vertically */
.box {
  height: 34px;
  width: 100%;
}
